import { useSelector } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import './ScrollingCategories.css';
import { useEffect, useRef } from 'react';
import isMobile from '../utils/isMobile';

export default function ScrollingCategories({ callBack, activeCategory }) {
    const categories = useSelector((state) => state.api.categories);

    const containerRef = useRef(null);

    const ref = useRef({
        mouseDown: false,
        mouseX: 0,
        startMouseX: 0,
        dragged: false,
    });

    useEffect(() => {
        // scroll the containter a little to the right and then back to the left
        setTimeout(
            () => {
                if (!containerRef.current) {
                    return;
                }
                containerRef.current.scroll({ left: 100, behavior: 'smooth' });
                setTimeout(() => {
                    if (!containerRef.current) {
                        return;
                    }
                    containerRef.current.scroll({ left: 0, behavior: 'smooth' });
                }, 500);
            },
            500,
        );
    }, [containerRef]);

    const scrollWheelContainer = (event) => {
        const delta = Math.abs(event.deltaY) > Math.abs(event.deltaX) ? event.deltaY : event.deltaX;
        containerRef.current.scrollLeft -= delta;
        event.preventDefault();
    };

    const startMouseDragging = (event) => {
        event.preventDefault();
        ref.current.mouseDown = true;
        ref.current.startMouseX = event.clientX;
        ref.current.mouseX = event.clientX;
    };

    const mouseDragCategories = (event) => {
        const { mouseDown, mouseX, startMouseX } = ref.current;
        if (!mouseDown) {
            return;
        }

        let draggedEv = false;

        if (Math.abs(mouseX - startMouseX) > 3) {
            draggedEv = true;
        }

        const dx = event.clientX - mouseX;
        containerRef.current.scrollLeft -= dx;

        if (draggedEv) {
            ref.current.mouseX = event.clientX;
            ref.current.dragged = true;
        } else {
            ref.current.mouseX = event.clientX;
        }
    };

    const stopMouseDragging = () => {
        ref.current.mouseDown = false;
    };

    const clickElement = (c) => {
        if (ref.current.dragged) {
            ref.current.dragged = false;
            return;
        }

        callBack(c);
    };

    useEffect(() => {
        if (!isMobile()) {
            // this is needed to convert a vertical scrolling div into a horizontal scrolling div

            const container = containerRef.current;
            if (container) {
                container.addEventListener('wheel', scrollWheelContainer, { passive: false });
                container.addEventListener('mousedown', startMouseDragging);
            }
            document.addEventListener('mousemove', mouseDragCategories, { passive: false });
            document.addEventListener('mouseup', stopMouseDragging, { passive: false });

            // cleanup
            return () => {
                if (container) {
                    container.removeEventListener('wheel', scrollWheelContainer);
                    container.removeEventListener('mousedown', startMouseDragging);
                }
                document.removeEventListener('mousemove', mouseDragCategories);
                document.removeEventListener('mouseup', stopMouseDragging);
            };
        }
        return () => {};
    }, []);

    return (
        <div ref={containerRef} className="categories">
            {categories.map((c) => (
                <button className={clsx(activeCategory !== c && 'inverted')} type="button" key={c} onClick={() => { clickElement(c); }}>{c}</button>
            ))}
        </div>
    );
}

ScrollingCategories.propTypes = {
    callBack: PropTypes.func.isRequired,
    activeCategory: PropTypes.string.isRequired,
};
