import PropTypes from 'prop-types';

export default function CircularProgress({
    stroke, strokeWidth, size, progress, className,
}) {
    const halfSize = size / 2;

    const radius = halfSize - strokeWidth * 2;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (progress / 100) * circumference;

    const style = {
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: `${offset}`,
        transition: 'stroke-dashoffset ease-in 0.2s',
        transform: 'rotate(-90deg)',
        transformOrigin: '50% 50%',
    };

    return (
        <svg
            width={size}
            height={size}
            className={className}
        >
            <circle
                style={style}
                stroke={stroke}
                strokeWidth={strokeWidth}
                fill="transparent"
                r={halfSize - strokeWidth * 2}
                cx={halfSize}
                cy={halfSize}
            />
        </svg>
    );
}

CircularProgress.propTypes = {
    stroke: PropTypes.string,
    strokeWidth: PropTypes.number,
    size: PropTypes.number,
    progress: PropTypes.number,
    className: PropTypes.string,
};

CircularProgress.defaultProps = {
    stroke: 'white',
    strokeWidth: 4,
    size: 100,
    progress: 100,
    className: '',
};
