/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { staticBase } from '../audio/audioActions';

export const fetchCategories = createAsyncThunk(
    'api/categories',
    async () => {
        // no cors
        const response = await fetch(`${staticBase}/sfx.json`);
        console.info('Fetched categories', response);
        const categories = await response.json();
        console.info('Fetched categories', categories);
        return { categories };
    },
);

export const fetchSfxs = createAsyncThunk(
    'api/sfxs',
    async (category, thunkAPI) => {
        const { sfxs } = thunkAPI.getState().api;

        console.info('Fetching sfxs', category);

        // if we already have the sfxs, don't fetch them again
        if (sfxs[category]) return { category, sfxs: sfxs[category] };

        const response = await fetch(`${staticBase}/sfx/${category}/list.json`);
        const data = await response.json();

        return { category, sfxs: data };
    },
);
