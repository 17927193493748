/* eslint-disable import/prefer-default-export */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Network } from '@poki/netlib';
import { fetchSfxs } from '../api/apiActions';
import { playAudio } from '../audio/audioActions';
import { NETWORK_STATE } from './networkInterface';

export const addPeer = createAction('network/addPeer');
export const removePeer = createAction('network/removePeer');
export const clearLobby = createAction('network/clearLobby');
export const noPeersError = createAction('network/noPeersError');

const RETRY_INTERVAL = 300;
const TIMEOUT = 5000;

const createLobbyWithTimeout = (network) => new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
        reject(new Error('Timeout'));
    }, TIMEOUT);

    const startTime = performance.now();

    network.once('lobby', (code) => {
        clearTimeout(timeout);
        if (performance.now() - startTime > TIMEOUT) {
            reject(new Error('Timeout after code received'));
        } else {
            resolve(code);
        }
    });

    network.create({ codeFormat: 'short' });
});

export const createLobby = createAsyncThunk(
    'network/createLobby',
    async (_, { getState, dispatch }) => {
        const { network, status } = getState().network;

        console.info('Attempting to create lobby');

        // Handle not being properly connected to the network
        if (status !== NETWORK_STATE.SUCCESS) {
            const error = status === NETWORK_STATE.ERROR
                ? 'Failed to connect to network'
                : 'Not connected to network';

            console.error(error);
            setTimeout(() => dispatch(createLobby()), RETRY_INTERVAL);
            throw new Error(error);
        }

        try {
            const code = await createLobbyWithTimeout(network);
            console.info('Created lobby', code);
            return { lobby: code };
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    },
);

export const joinLobby = createAsyncThunk(
    'network/joinLobby',
    async (code, thunkAPI) => {
        const { network, status } = thunkAPI.getState().network;

        console.info('Attempting to join lobby', code);

        // if we are not connected to the network attempt to joinLobby again unless we are in an error state
        if (status !== NETWORK_STATE.SUCCESS) {
            if (status === NETWORK_STATE.ERROR) {
                console.error('Failed to connect to network');
                throw new Error('Failed to connect to network');
            }
            // wait a bit before trying to connect again
            setTimeout(() => {
                thunkAPI.dispatch(joinLobby(code));
            }, RETRY_INTERVAL);
            throw new Error('Not connected to network');
        }

        const lobby = await new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Timeout'));
            }, TIMEOUT);

            network.on('lobby', (c) => {
                console.info('Joined lobby', c);
                clearTimeout(timeout);
                resolve(c);
            });

            network.on('signalingerror', (err) => {
                console.error('Signaling error', err);
                reject(err);
            });

            network.join(code).catch((err) => {
                console.info('Failed to join lobby', err);
                reject(err);
            });
        });

        return { lobby };
    },
);

export const sendAudio = createAsyncThunk(
    'network/sendAudio',
    async ({ path }, { dispatch, getState }) => {
        const { network } = getState().network;
        const { categories } = getState().api;

        const category = path.split('/')[2];
        const sfx = path.split('/')[3].split('.')[0];

        const categoryIndex = categories.indexOf(category);

        const { payload: { sfxs } } = await dispatch(fetchSfxs(category));

        const sfxIndex = sfxs.indexOf(sfx);

        const audioBuffer = new Uint16Array([categoryIndex, sfxIndex]).buffer;

        network.broadcast('reliable', audioBuffer);

        console.info('Sent audio', category, sfx);
    },
);

export const receiveAudio = createAsyncThunk(
    'network/receiveAudio',
    async (audioBuffer, { dispatch, getState }) => {
        const { categories } = getState().api;

        console.info('Received audio!', audioBuffer);

        const audioData = new Uint16Array(audioBuffer);

        const category = categories[audioData[0]];
        const { payload: { sfxs } } = await dispatch(fetchSfxs(category));

        const sfx = sfxs[audioData[1]];

        const path = `/sfx/${category}/${sfx}.mp3`;

        console.info('Received audio', category, sfx);

        dispatch(playAudio({ path }));
    },
);

export const connectNetwork = createAsyncThunk(
    'network/connect',
    async (id, thunkAPI) => {
        const network = new Network(id);

        console.info('Connecting to network', id);

        network.on('signalingerror', (err) => {
            console.info('Signaling error', err);
        });
        network.on('rtcerror', (err) => {
            console.info('RTC error', err);
        });

        network.on('connecting', (peer) => {
            console.info('connecting to peer', peer);
        });

        network.on('disconnected', (peer) => {
            thunkAPI.dispatch(removePeer({ peer }));
        });
        network.on('connected', (peer) => {
            console.info('Connected to peer', peer);
            thunkAPI.dispatch(addPeer({ peer }));
        });

        network.on('message', (peer, channel, data) => {
            if (data instanceof ArrayBuffer) {
                thunkAPI.dispatch(receiveAudio(data));
            }
        });

        network.on('close', (reason) => {
            if (reason === 'leave lobby') {
                const safeTimeoutToReconnect = 100;
                setTimeout(() => {
                    thunkAPI.dispatch(connectNetwork(id));
                }, safeTimeoutToReconnect);
            }
        });

        await new Promise((resolve) => {
            network.on('ready', () => {
                console.info('Connected to network', id);
                resolve();
            });
        });

        return { network };
    },
);
