/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { fetchCategories, fetchSfxs } from './apiActions';

export const apiSlice = createSlice({
    name: 'api',
    initialState: {
        categories: [],
        sfxs: {},
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categories = action.payload.categories;
            })
            .addCase(fetchSfxs.fulfilled, (state, action) => {
                state.sfxs[action.payload.category] = action.payload.sfxs;
            });
    },
});

// gets a category and returns the sfxs for that category
export const selectSFXs = createSelector(
    (state) => state.api.sfxs,
    (_, category) => category,
    (sfxs, category) => sfxs[category] || [],
);

export default apiSlice.reducer;
