/* eslint-disable max-len */

import PropTypes from 'prop-types';

export default function AudioIcon({ fill }) {
    return (
        <svg viewBox="0 0 100 100" fill={fill}>
            <path d="M51 19.9c-1.5-.7-3.2-.5-4.5.5L26.8 36H13.2c-2.4 0-4.3 1.9-4.3 4.3v19.4c0 2.4 1.9 4.3 4.3 4.3h13.6l19.7 15.6c1.9 1.5 4.6 1.2 6-.7q.9-1.2.9-2.7V23.8c0-1.7-.9-3.2-2.4-3.9m18.2 14.9c-1.7-1.7-4.4-1.7-6.1 0s-1.7 4.4 0 6.1c5 5 5 13.2 0 18.3-1.7 1.7-1.7 4.4 0 6.1.8.8 1.9 1.3 3 1.3s2.2-.4 3-1.3c4-4 6.3-9.5 6.3-15.2s-2.2-11.2-6.3-15.2" />
            <path d="M74.2 23.7c-1.7 1.7-1.7 4.4 0 6.1 11.2 11.2 11.2 29.3 0 40.5-1.6 1.7-1.6 4.4.1 6.1 1.7 1.6 4.3 1.6 5.9 0 14.5-14.5 14.5-38.1 0-52.6-1.7-1.7-4.4-1.7-6.1 0" />
        </svg>
    );
}

AudioIcon.propTypes = {
    fill: PropTypes.string,
};

AudioIcon.defaultProps = {
    fill: 'currentColor',
};
