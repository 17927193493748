/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { Link } from 'react-router-dom';

import { createLobby, joinLobby } from '../features/netlib/networkActions';

import './Start.css';
import { staticBase } from '../features/audio/audioActions';

const { pathname } = window.location;

function Start() {
    const dispatch = useDispatch();

    const inputRef = useRef(null);
    const [connecting, setConnecting] = useState(false);
    const [joining, setJoining] = useState(pathname.length === 5);
    const [codeError, setCodeError] = useState(false);
    const [code, setCode] = useState(pathname.length === 5 ? pathname.slice(1).toUpperCase() : '');
    const audioInitializedRef = useRef(false);

    const lobbyConnecting = useSelector((state) => state.network.lobbyConnecting);

    useEffect(() => {
        if (connecting && !lobbyConnecting) {
            setConnecting(false);

            if (joining) {
                setCodeError(true);
            }
        }
    }, [connecting, joining, lobbyConnecting]);

    const enableAudio = () => {
        if (audioInitializedRef.current) {
            return;
        }

        window.audioComponent = new Audio();
        window.audioComponent.src = `${staticBase}/sfx/noise.mp3`;
        window.audioComponent.volume = 0.1;
        window.audioComponent.play().catch((error) => console.error(error));

        // try to keep the application awake even with the screen off
        window.keepAwakeComponent = new Audio();
        window.keepAwakeComponent.src = `${staticBase}/sfx/noise.mp3`;
        window.keepAwakeComponent.loop = true;
        window.keepAwakeComponent.volume = 0.1;
        window.keepAwakeComponent.play().catch((error) => console.error(error));

        audioInitializedRef.current = true;
    };

    const onStartClick = () => {
        enableAudio();

        setConnecting(true);
        dispatch(createLobby());
    };

    const onJoinClick = () => {
        setJoining(true);
    };

    const onCodeChange = (value) => {
        setCode(value);
    };

    const onJoinWithCodeClick = () => {
        enableAudio();

        if (code.length === 4) {
            dispatch(joinLobby(code.toUpperCase()));
            setConnecting(true);
        }
    };

    const onJoinBackClick = () => {
        setJoining(false);
        setCode('');
    };

    const renderJoin = () => (
        <>
            <ReactCodeInput
                ref={inputRef}
                className="code-input"
                type="text"
                fields={4}
                value={code}
                onChange={onCodeChange}
            />
            {codeError && <div className="error">Invalid code</div>}
            <button className="join" type="button" onClick={onJoinWithCodeClick}>Join</button>
            <button className="back secondary_button" type="button" onClick={onJoinBackClick}>Back</button>
        </>
    );

    return (
        <div className="start">
            {connecting && <div className="connecting">Connecting...</div>}
            {joining && renderJoin()}
            {!connecting && !joining && (
                <>
                    <div className="start-buttons">
                        <button type="button" onClick={onStartClick}>Start Room</button>
                        <button type="button" className="inverted" onClick={onJoinClick}>Join Room</button>
                    </div>

                    <p>
                        Prank friends and family easily with
                        {' '}
                        <b>AudioPrank</b>
                        !
                        <br />
                        <br />

                        1.
                        {' '}
                        <b>Start</b>
                        {' '}
                        a room
                        <br />
                        2.
                        {' '}
                        <b>Join</b>
                        {' '}
                        from another device
                        <br />
                        3.
                        {' '}
                        <b>Send</b>
                        {' '}
                        funny sounds.
                    </p>
                    <Link to="/prank-ideas" className="subtle">Prank Ideas</Link>
                    <Link to="/sound-trivia" className="subtle">Sound Trivia</Link>
                    <Link to="/frequently-asked-questions" className="subtle">FAQ</Link>
                </>
            )}
        </div>
    );
}

export default Start;
