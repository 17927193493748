/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';

import './PrankIdeas.css';

function PrankIdeas() {
    const navigate = useNavigate();

    const onPrivacyBackClick = () => {
        navigate('/');
    };

    return (
        <div className="prank-ideas">
            <button className="privacy_back secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>

            <h2>Prank Ideas</h2>

            <div className="disclaimer">
                <strong>Disclaimer:</strong>
                &nbsp;
                AudioPrank is not responsible for any pants-wetting, spit-takes, or friendships temporarily put on hold due to the use of our pranks. Prank responsibly!
            </div>

            <details open>
                <summary><h3>Prank Idea 1: Dare Devils</h3></summary>
                <p>
                    Connect one or more of your friends to the same Audio Prank room during class, in the library or an other situation where silence is appreciated. Every now and then one of you has to play a sound that is a bit more daring than the previous one. The last one to stay in the room wins.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 2: Meeting Madness</h3></summary>
                <p>
                    Join an online meeting with a group of friends. Take turns playing subtle sounds like a sneeze, a cough, or a keyboard typing noise. See if you can keep a straight face while others try to figure out where the sounds are coming from.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 3: Sleepover Surprise</h3></summary>
                <p>
                    During a sleepover, wait until everyone is about to fall asleep and then play creepy sounds like footsteps or ghostly whispers from a hidden device. Watch your friends&apos; reactions as they hear these unexpected noises in the dark.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 4: Coffee Shop Commotion</h3></summary>
                <p>
                    Sit in a busy coffee shop and connect to a friend&apos;s device. Play random sounds like a baby crying or a loud ringtone. See how many heads turn and watch your friend try to explain the mysterious noises.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 5: Bathroom Echoes</h3></summary>
                <p>
                    Place a device in the bathroom and play embarrassing noises like farts and diarrhea sounds at unexpected times. This can be especially funny if someone is using the bathroom and hears these noises out of the blue.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 6: Public Transit Troublemaker</h3></summary>
                <p>
                    While on a bus or train, connect to a friend&apos;s device and play sounds like a phone ringing or an announcement chime. Watch as passengers around you look confused, trying to figure out where the noises are coming from.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 7: Library Laughter</h3></summary>
                <p>
                    In a library, connect to a friend&apos;s device and play sounds like a quiet giggle or a shushing noise. The challenge is to do it without getting caught by the librarians or other patrons.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 8: Elevator Escapade</h3></summary>
                <p>
                    Hide a device in an elevator and play sounds like elevator music or an unexpected ding. Watch as people react to the strange noises while they&apos;re confined in the small space.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 9: Park Bench Prank</h3></summary>
                <p>
                    Sit on a park bench with a friend and connect to a device hidden nearby. Play sounds like birds chirping or a dog barking. See if passersby notice the unusual frequency or volume of the sounds.
                </p>
            </details>

            <details open>
                <summary><h3>Prank Idea 10: Backyard Haunting</h3></summary>
                <p>
                    Place a device in your backyard or garden and play haunting noises like ghostly whispers, chains rattling, or eerie footsteps. Do this at night when it’s dark to create a spooky atmosphere. Watch as your friends or family members try to figure out where the creepy sounds are coming from.
                </p>
            </details>
            <button className="privacy_back_last secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>
        </div>
    );
}

export default PrankIdeas;
