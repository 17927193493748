/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './PlayButton.css';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { playAudio } from '../features/audio/audioActions';
import { noPeersError, sendAudio } from '../features/netlib/networkActions';
import AudioIcon from '../icons/AudioIcon.jsx';
import CircularProgress from '../icons/CircularProgress.jsx';
import useWindowSize from '../hooks/ResizeHook.jsx';

export default function PlayButton({ name, path }) {
    const dispatch = useDispatch();

    const windowSize = useWindowSize();

    const peers = useSelector((state) => state.network.peers) || [];

    const [sending, setSending] = useState(false);
    const [sendProgress, setSendProgress] = useState(0);
    const [sendError, setSendError] = useState(false);

    const setProgressTimeout = useRef(null);
    const resetStateTimeout = useRef(null);

    const onTest = () => {
        dispatch(playAudio({ path }));
    };

    const onPlay = () => {
        dispatch(sendAudio({ path }));
        setSending(true);

        if (peers.length === 0) {
            setSendError(true);
            dispatch(noPeersError());
        } else {
            setSendError(false);
        }

        if (setProgressTimeout.current) {
            clearTimeout(setProgressTimeout.current);
        }

        if (resetStateTimeout.current) {
            clearTimeout(resetStateTimeout.current);
        }

        setProgressTimeout.current = setTimeout(() => {
            setSendProgress(100);
        }, 0);

        const resetTime = peers.length === 0 ? 1000 : 300;

        resetStateTimeout.current = setTimeout(() => {
            setSending(false);
            setSendProgress(0);
            setSendError(false);
        }, resetTime);
    };

    const onPlayDown = () => {
        setSending(false);
        setSendProgress(0);
    };

    useEffect(() => () => {
        if (setProgressTimeout.current) {
            clearTimeout(setProgressTimeout.current);
        }

        if (resetStateTimeout.current) {
            clearTimeout(resetStateTimeout.current);
        }
    }, []);

    let buttonText = name;

    if (sending) {
        buttonText = sendError ? 'connect device' : 'sent';
    }

    const circleRadius = windowSize[0] >= 486 ? 120 : 110;
    const stroke = sendError ? 'var(--negative-color)' : 'var(--secondary-color)';

    return (
        <div className="button_container">
            <div>
                <button className={clsx('play', sendError && 'error')} type="button" onClick={onPlay} onPointerDown={onPlayDown}>
                    {buttonText}
                </button>
                <div className="play_shadow" />
            </div>
            <button className="preview secondary_button" type="button" onClick={onTest} aria-label="Preview Audio"><AudioIcon /></button>

            {sending && <CircularProgress className="send_progress" stroke={stroke} size={circleRadius} progress={sendProgress} />}
        </div>
    );
}

PlayButton.propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
