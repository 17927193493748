import { useNavigate } from 'react-router-dom';

import './FAQ.css';

function FAQ() {
    const navigate = useNavigate();

    const onPrivacyBackClick = () => {
        navigate('/');
    };

    const onEmail = () => {
        const email64 = 'dGVybWluYXJjaGdhbWVzQGdtYWlsLmNvbQ==';
        const email = `mailto:${atob(email64)}`;
        window.open(email, '_blank');
    };
    return (
        <div className="faq">
            <button className="privacy_back secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>

            <h2>Frequently Asked Questions</h2>
            <details>
                <summary><h3>What is AudioPrank?</h3></summary>
                <p>
                    AudioPrank is a web application that allows you to prank your friends and family with funny sounds.
                    It&apos;s all you need to prank your friends, you can throw away your bluetooth speakers and cables.
                    You can start a room, join the room from another device, and send funny sounds from one device to another.
                </p>
            </details>
            <details>
                <summary><h3>Is AudioPrank free to use?</h3></summary>
                <p>
                    Yes, AudioPrank is completely free to use.
                    We aim to provide a fun and engaging way to connect with your friends and family without any cost.
                </p>
            </details>
            <details>
                <summary><h3>Can I create / submit sounds to use in AudioPrank?</h3></summary>
                <p>
                    Currently, you cannot upload your own sounds to AudioPrank.
                    We provide a curated selection of sounds designed to ensure the best pranking experience.
                    However you can suggest sounds by
                    <button type="button" className="email" onClick={onEmail}>emailing</button>
                    us.
                </p>
            </details>
            <details>
                <summary><h3>How do I start a room?</h3></summary>
                <p>Click the &quot;Start Room&quot; button on the home page. A room code will be displayed at the top of the page.</p>
            </details>
            <details>
                <summary><h3>How do I join a room?</h3></summary>
                <p>
                    Click the &quot;Join Room&quot; button on the home page, then enter the room code which is displayed at the top of the page.
                    You can also join a room by scanning the QR code with your mobile device. Or pressing the share button to share the link.
                </p>
            </details>
            <details>
                <summary><h3>How do I send a sound?</h3></summary>
                <p>Click on the big white buttons with text to send a sound to the other device.</p>
            </details>
            <details>
                <summary><h3>How do I preview a sound?</h3></summary>
                <p>Click on the small green speaker icon next to the white buttons to preview a sound.</p>
            </details>
            <details>
                <summary><h3>How do I leave a room?</h3></summary>
                <p>Click the &quot;Leave&quot; button at the top of the page.</p>
            </details>
            <details>
                <summary><h3>Which devices are supported?</h3></summary>
                <p>
                    AudioPrank works on all devices that have a web browser and support WebRTC. This includes
                    <ul style={{ textAlign: 'left' }}>
                        <li>Android devices</li>
                        <li>iPhone and iPad devices</li>
                        <li>Smart Watches</li>
                        <li>Windows computers & laptops</li>
                        <li>Mac computers & laptops</li>
                        <li>Linux computers & laptops</li>
                        <li>Chromebooks</li>
                        <li>Smart TVs</li>
                        <li>Game consoles</li>
                    </ul>
                </p>
            </details>
            <details>
                <summary><h3>How do I report a problem or give feedback?</h3></summary>
                <p>
                    You can report problems by
                    <button type="button" className="email" onClick={onEmail}>emailing</button>
                    us.
                    We are committed to improving AudioPrank and appreciate all user input.
                </p>
            </details>
            <button className="privacy_back_last secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>
        </div>
    );
}

export default FAQ;
