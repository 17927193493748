import { configureStore } from '@reduxjs/toolkit';

import networkReducer from '../features/netlib/networkSlice';
import apiReducer from '../features/api/apiSlice';
import audioReducer from '../features/audio/audioSlice';

export default configureStore({
    reducer: {
        api: apiReducer,
        network: networkReducer,
        audio: audioReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['network/connect/fulfilled', 'network/addPeer', 'network/removePeer', 'audio/playAudio'],
            ignoredPaths: ['network.network', 'network.peers', 'audio.audioComponent', 'audio.keepAwakeComponent'],
        },
    }),
});
