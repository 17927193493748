/* eslint-disable max-len */

import PropTypes from 'prop-types';

export default function DevicesIcon({ fill, size }) {
    return (
        <svg viewBox="0 0 24 24" fill={fill} width={size} height={size}>
            <path d="M3 6h18V4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3zm10 6H9v1.78c-.61.55-1 1.33-1 2.22s.39 1.67 1 2.22V20h4v-1.78c.61-.55 1-1.34 1-2.22s-.39-1.67-1-2.22zm-2 5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5M22 8h-6c-.5 0-1 .5-1 1v10c0 .5.5 1 1 1h6c.5 0 1-.5 1-1V9c0-.5-.5-1-1-1m-1 10h-4v-8h4z" />
        </svg>
    );
}

DevicesIcon.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
};

DevicesIcon.defaultProps = {
    fill: 'currentColor',
    size: 24,
};
