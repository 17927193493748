import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './Lock.css';

const maxValue = 150;
const speed = 12;

export default function Lock({ unlock }) {
    const rafID = useRef(null);
    const lockRef = useRef(null);
    const curtainRef = useRef(null);
    const darkenTimeout = useRef(0);

    const currValue = useRef(0);

    const startDrag = () => {
        window.cancelAnimationFrame(rafID.current);
        currValue.current = lockRef ? +lockRef.current.value : 0;
    };

    const successHandler = () => {
        unlock?.();
        lockRef.current.value = 0;
    };

    const animateHandler = () => {
        if (!lockRef.current) return;

        lockRef.current.value = currValue.current;
        console.info('currValue', currValue);
        console.info('lock ref value', lockRef.current.value);

        if (currValue.current > -1) {
            rafID.current = window.requestAnimationFrame(animateHandler);

            console.info('currValue', currValue.current);
        }
        currValue.current -= speed;

        console.info('animateHandler');
    };

    const endDrag = () => {
        currValue.current = lockRef ? +lockRef.current.value : 0;

        // determine if we have reached success or not
        if (currValue.current >= maxValue) {
            successHandler();
        } else {
            window.requestAnimationFrame(animateHandler);
        }
    };

    const onPointerInteraction = () => {
        clearTimeout(darkenTimeout.current);
        curtainRef.current.style.opacity = 0;
        curtainRef.current.style.transition = 'opacity 300ms ease-out';

        darkenTimeout.current = setTimeout(() => {
            clearTimeout(darkenTimeout.current);
            curtainRef.current.style.opacity = 1;
            curtainRef.current.style.transition = 'opacity 2000ms';
        }, 1000);
    };

    useEffect(() => {
        if (!lockRef.current) return;
        lockRef.current.addEventListener('mousedown', startDrag, false);
        lockRef.current.addEventListener('mousestart', startDrag, false);
        lockRef.current.addEventListener('mouseup', endDrag, false);
        lockRef.current.addEventListener('touchend', endDrag, false);

        document.addEventListener('pointerdown', onPointerInteraction, false);
        document.addEventListener('pointermove', onPointerInteraction, false);

        document.body.style.backgroundColor = 'black';
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#000000');

        onPointerInteraction();

        const lockElement = lockRef.current;

        // eslint-disable-next-line consistent-return
        return () => {
            lockElement.removeEventListener('mousedown', startDrag, false);
            lockElement.removeEventListener('mousestart', startDrag, false);
            lockElement.removeEventListener('mouseup', endDrag, false);
            lockElement.removeEventListener('touchend', endDrag, false);

            document.removeEventListener('pointerdown', onPointerInteraction, false);
            document.removeEventListener('pointermove', onPointerInteraction, false);

            document.body.style.backgroundColor = '#0251D0';
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#0251D0');

            clearTimeout(darkenTimeout.current);
        };
    });

    return (
        <div className="lock">
            <div ref={curtainRef} className="lock_background" />
            <input ref={lockRef} type="range" defaultValue="0" min="0" max={maxValue} className="pullee" />
            <span className="slide_unlock">Slide to Unlock</span>
        </div>
    );
}

Lock.defaultProps = {
    unlock: () => {},
};

Lock.propTypes = {
    unlock: PropTypes.func,
};
