/* eslint-disable max-len */

import PropTypes from 'prop-types';

export default function LockIcon({
    fill, size, className, onClick,
}) {
    return (
        <svg
            viewBox="0 0 18 25"
            fill={fill}
            width={size}
            height={size}
            className={className}
            onClick={onClick}
        >
            <path d="M16 10V7c0-3.87-3.13-7-7-7S2 3.13 2 7v3c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V12c0-1.1-.9-2-2-2m-2 0H4V7c0-2.76 2.24-5 5-5s5 2.24 5 5z" />
        </svg>
    );
}

LockIcon.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

LockIcon.defaultProps = {
    fill: 'currentColor',
    size: 24,
    className: '',
    onClick: () => {},
};
