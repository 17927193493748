/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { staticBase } from './audioActions';

export const audioSlice = createSlice({
    name: 'audio',
    initialState: {},
    reducers: {
        playAudio: (state, { payload: { path } }) => {
            window.audioComponent.src = staticBase + path;
            window.audioComponent.volume = 1.0;
            window.audioComponent.play().catch((error) => console.error(error));
        },
    },
});

export default audioSlice.reducer;
