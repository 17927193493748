import './Footer.css';

import { Link } from 'react-router-dom';
import useWindowSize from '../hooks/ResizeHook.jsx';

export default function Footer() {
    const size = useWindowSize();

    const privacyText = size[0] >= 486 ? 'Privacy Policy' : 'Privacy';
    const termsText = size[0] >= 486 ? 'Terms & Conditions' : 'Terms';

    const onDMCA = () => {
        const email64 = 'dGVybWluYXJjaGdhbWVzQGdtYWlsLmNvbQ==';
        const subject = 'DMCA';
        const body = 'Please provide the URL of the content you want removed.';
        const email = `mailto:${atob(email64)}?subject=${subject}&body=${body}`;
        window.open(email, '_blank');
    };

    return (
        <div className="footer">
            <button type="button" className="secondary_button" onClick={onDMCA}>DMCA</button>
            <Link to="/privacy-policy" className="secondary_button">{privacyText}</Link>
            <Link to="/terms-and-conditions" className="secondary_button">{termsText}</Link>
            <div className="footer_text">
                &copy; 2024 Terminarch Games
            </div>
        </div>
    );
}
