import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import DevicesIcon from '../icons/DevicesIcon.jsx';

import './Peers.css';

export default function Peers() {
    const peers = useSelector((state) => state.network.peers) || [];
    const peerErrorState = useSelector((state) => state.network.peerError);

    const peerRef = useRef(null);
    const [peerError, setPeerError] = useState(peerErrorState);

    useEffect(
        () => {
            if (peerErrorState !== peerError) {
                setPeerError(peerErrorState);

                peerRef.current.classList.remove('error-animate');
                // eslint-disable-next-line no-void
                void peerRef.current.offsetWidth;
                peerRef.current.classList.add('error-animate');
            }
        },
        [peerErrorState, peerError],
    );

    return (
        <div ref={peerRef} className="peers" data-tooltip-id="tooltip" data-tooltip-content="connected devices">
            <DevicesIcon size={22} />
            {' '}
            {peers.length}
        </div>
    );
}
