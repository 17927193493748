/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
    connectNetwork, createLobby, joinLobby,
} from './networkActions';
import { NETWORK_STATE } from './networkInterface';

export const networkSlice = createSlice({
    name: 'network',
    initialState: {
        status: NETWORK_STATE.IDLE,
        network: null,
        lobby: null,
        lobbyConnecting: false,
        peers: [],
        peerError: 0,
    },
    reducers: {
        addPeer: (state, { payload: { peer } }) => {
            if (!state.peers.includes(peer.id)) {
                state.peers.push(peer.id);
            }
        },

        removePeer: (state, { payload: { peer } }) => {
            state.peers = state.peers.filter((id) => id !== peer.id);
        },
        clearLobby: (state) => {
            if (!state.lobby) return;

            state.lobby = null;
            state.lobbyConnecting = false;

            if (state.network) state.network.close('leave lobby');
        },
        noPeersError: (state) => {
            state.peerError += 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(connectNetwork.pending, (state) => {
                state.status = NETWORK_STATE.LOADING;
            })
            .addCase(connectNetwork.fulfilled, (state, action) => {
                state.status = NETWORK_STATE.SUCCESS;
                state.network = action.payload.network;
            })
            .addCase(connectNetwork.rejected, (state) => {
                state.status = NETWORK_STATE.ERROR;
            })
            .addCase(createLobby.fulfilled, (state, action) => {
                state.lobby = action.payload.lobby;
            })
            .addCase(createLobby.rejected, (_, error) => {
                console.error('Failed to create lobby', error);
            })
            .addCase(joinLobby.pending, (state) => {
                state.lobbyConnecting = true;
            })
            .addCase(joinLobby.fulfilled, (state, action) => {
                state.lobbyConnecting = false;
                state.lobby = action.payload.lobby;
            })
            .addCase(joinLobby.rejected, (state, action) => {
                console.info('Failed to join lobby', action);
                if (action.error.message !== 'Not connected to network') {
                    state.lobbyConnecting = false;
                }
            });
    },
});

export default networkSlice.reducer;
