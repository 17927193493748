/* eslint-disable max-len */

import PropTypes from 'prop-types';

export default function QRIcon({
    fill, size, className, onClick,
}) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill={fill}
            width={size}
            height={size}
            className={className}
            onClick={onClick}
            data-tooltip-id="tooltip-qr"
            data-tooltip-content="generate QR code"
            data-tooltip-delay-hide="500"
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M16 17v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1zm5 4h-4v-2h2v-2h2zM3 3h8v8H3zm2 2v4h4V5zm8-2h8v8h-8zm2 2v4h4V5zM3 13h8v8H3zm2 2v4h4v-4zm13-2h3v2h-3zM6 6h2v2H6zm0 10h2v2H6zM16 6h2v2h-2z" />
        </svg>
    );
}

QRIcon.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

QRIcon.defaultProps = {
    fill: 'currentColor',
    size: 24,
    className: '',
    onClick: () => {},
};
