/* eslint-disable max-len */

import PropTypes from 'prop-types';

export default function ShareIcon({
    stroke, size, className, onClick,
}) {
    return (
        <svg viewBox="0 0 24 24" fill="none" width={size} height={size} className={className} onClick={onClick}>
            <path stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 6-3-3m0 0L9 6m3-3v12m4-5h1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h1" />
        </svg>
    );
}

ShareIcon.propTypes = {
    stroke: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

ShareIcon.defaultProps = {
    stroke: 'currentColor',
    size: 24,
    className: '',
    onClick: () => {},
};
