/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Route, Routes, useNavigate,
} from 'react-router-dom';

import NoSleep from '@uriopass/nosleep.js';

import { Tooltip } from 'react-tooltip';
import { clearLobby, connectNetwork } from './features/netlib/networkActions';
import { fetchCategories } from './features/api/apiActions';

import Start from './components/Start.jsx';
import Lobby from './components/Lobby.jsx';

import './App.css';
import Footer from './components/Footer.jsx';
import Privacy from './documents/Privacy.jsx';
import Terms from './documents/Terms.jsx';
import Peers from './components/Peers.jsx';
import FAQ from './components/FAQ.jsx';
import PrankIdeas from './components/PrankIdeas.jsx';
import SoundTrivia from './components/SoundTrivia.jsx';

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lobby = useSelector((state) => state.network.lobby);

    const [key, setKey] = useState(0);

    useEffect(() => {
        // connect to the netlib
        const uid = '5426b20a-0133-4e9f-abc4-1e77e9602565';
        dispatch(connectNetwork(uid));

        // fetch the categories
        dispatch(fetchCategories());

        const noSleep = new NoSleep();
        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        // when user switched tabs, set the tab name to something not obvious
        document.addEventListener('visibilitychange', () => {
            const link = document.querySelector("link[rel~='icon']");

            if (document.visibilityState === 'hidden') {
                document.title = '...';
                link.href = 'https://google.com/favicon.ico';
            } else {
                const ogTitle = document.querySelector('meta[property="og:title"]');
                document.title = ogTitle ? ogTitle.content : 'AudioPrank';
                link.href = 'https://audioprank.com/favicon.ico';
            }
        });
    }, [dispatch]);

    useEffect(() => {
        const pages = ['/privacy-policy', '/terms-and-conditions', '/frequently-asked-questions', '/prank-ideas', '/sound-trivia'];
        if (lobby) {
            if (window.location.pathname !== `/${lobby}`) {
                // navigate to /<lobby>
                navigate(`/${lobby}`);
            }
        } else if (!pages.includes(window.location.pathname)) {
            navigate('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lobby]);

    const onTitleClick = () => {
        navigate('');
        dispatch(clearLobby());
        setKey((prev) => prev + 1);
    };

    return (
        <div className="app">
            {lobby && <Peers />}
            <button className="unstyled_button title" type="button" onClick={onTitleClick}>
                <h1>AUDIOPRANK</h1>
            </button>

            <Routes>
                <Route path="/" element={<Start key={key} />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/frequently-asked-questions" element={<FAQ />} />
                <Route path="/prank-ideas" element={<PrankIdeas />} />
                <Route path="/sound-trivia" element={<SoundTrivia />} />
                <Route path="/:lobby" element={<Lobby />} />
            </Routes>

            <Footer />
            <div className="smile top" />
            <div className="smile bot" />
            <div className="fiddle" />

            <Tooltip id="tooltip" />
            <Tooltip id="tooltip-qr" />
        </div>
    );
}

export default App;
