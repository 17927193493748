import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { fetchSfxs } from '../features/api/apiActions';
import { selectSFXs } from '../features/api/apiSlice';
import { clearLobby } from '../features/netlib/networkActions';

import QRIcon from '../icons/QRIcon.jsx';
import LockIcon from '../icons/LockIcon.jsx';
import ShareIcon from '../icons/ShareIcon.jsx';
import isMobile from '../utils/isMobile';
import PlayButton from './PlayButton.jsx';

import './Lobby.css';
import ScrollingCategories from './ScrollingCategories.jsx';
import Lock from './Lock.jsx';

function Lobby() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lobby = useSelector((state) => state.network.lobby);
    const categories = useSelector((state) => state.api.categories);
    const [qrcode, setQRcode] = useState('');
    const peerErrorState = useSelector((state) => state.network.peerError);
    const [peerError, setPeerError] = useState(peerErrorState);
    const [showPopup, setShowPopup] = useState(false);
    const [popupShown, setPopupShown] = useState(false);
    const [lockScreen, setLockScreen] = useState(false);

    const [category, setCategory] = useState('');
    const sfxs = useSelector((state) => selectSFXs(state, category));

    useEffect(
        () => {
            if (categories.length > 0) {
                const c = categories[0];
                dispatch(fetchSfxs(c));
                setCategory(c);
            }
        },
        [dispatch, categories],
    );

    useEffect(
        () => {
            if (category) {
                dispatch(fetchSfxs(category));
            }
        },
        [dispatch, category],
    );

    useEffect(() => () => {
        // cleanup on unmount
        dispatch(clearLobby());
    }, [dispatch]);

    const onLobbyBackClick = () => {
        navigate('/');
        dispatch(clearLobby());
    };

    const onQRClick = () => {
        if (qrcode) {
            setQRcode('');
            return;
        }

        import('qrcode').then(async (qr) => {
            const url = `${window.location.origin}${window.location.pathname}`;
            const canvas = document.createElement('canvas');

            try {
                const qrCode = await qr.toDataURL(canvas, url, { margin: 2, width: 400, color: { dark: '#0251D0', light: '#FFF' } });
                setQRcode(qrCode);
            } catch (e) {
                console.error(e);
            }
        });
    };

    const onQRImageClick = () => {
        setQRcode('');
    };

    const onShareClick = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Unleash mischief with AudioPrank!',
                text: 'Tap to join the fun!',
                url: window.location.href,
            });
        }
    };

    const onLockClick = () => {
        setLockScreen(true);
        // request full screen
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    };

    const unlockScreen = () => {
        setLockScreen(false);

        if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
            // exit full screen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    useEffect(
        () => {
            if (peerErrorState !== peerError) {
                setPeerError(peerErrorState);
                if (!popupShown) {
                    setShowPopup(true);
                    setPopupShown(true);
                }
            }
        },
        [peerErrorState, peerError, popupShown],
    );

    return (
        <div className="lobby">
            <div className="lobby_code">
                {lobby}
                <QRIcon
                    onClick={onQRClick}
                    className={clsx('qr', qrcode && 'active', !isMobile() && 'desktop')}
                    fill="var(--primary-color)"
                    size={44}
                />
                {isMobile() && <ShareIcon className="share" stroke="var(--primary-color)" size={44} onClick={onShareClick} />}
                <LockIcon className="lock_screen" fill="var(--primary-color)" size={44} onClick={onLockClick} />
                <button className="lobby_back secondary_button" type="button" onClick={onLobbyBackClick}>Leave</button>
            </div>

            {qrcode && (
                <button type="button" className="unstyled_button lobby_qr" onClick={onQRImageClick}>
                    <img src={qrcode} alt="QR Code" />
                </button>
            )}

            {showPopup && (
                <>
                    <div className="popup">
                        <h3>Connect a device</h3>
                        <p>
                            To send sounds, connect another device. Enter the code above to join the room.
                            Preview a sound by clicking the little speaker icon, see image below.
                        </p>
                        <img src="/img/preview.png" alt="Preview" />
                        <button className="popup_button secondary_button" type="button" onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div className="popup_blackout" onClick={() => setShowPopup(false)} />
                </>
            )}

            <ScrollingCategories callBack={setCategory} activeCategory={category} />

            {lockScreen && <Lock unlock={unlockScreen} />}

            <div className="sfx_holder">
                {sfxs.map((sfx) => {
                    const path = `/sfx/${category}/${sfx}.mp3`;
                    return (
                        <PlayButton key={sfx} name={sfx} path={path} />
                    );
                })}
            </div>
        </div>
    );
}

export default Lobby;
