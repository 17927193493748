/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';

import './SoundTrivia.css';

function SoundTrivia() {
    const navigate = useNavigate();

    const onPrivacyBackClick = () => {
        navigate('/');
    };

    return (
        <div className="sound-trivia">
            <button className="privacy_back secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>

            <h2>Sound Trivia</h2>

            <details>
                <summary><h3>The Brain Fart Sound</h3></summary>
                <p>
                    Ever had a moment when you totally forgot something or did something super silly? That&apos;s what we call a &quot;brain fart&quot;! The brain fart sound is a funny noise that makes us laugh when we make those goofy mistakes. You might hear this sound in cartoons or funny movies. It&apos;s like a way to say, &quot;Oops, I did something silly!&quot; Even though it&apos;s all in good fun, everyone has brain fart moments, so it&apos;s a great reminder to giggle at ourselves and not take things too seriously.
                </p>
            </details>

            <details>
                <summary><h3>The Wet Fart Sound</h3></summary>
                <p>
                    The wet fart sound is a funny noise that&apos;s become famous on the internet. It first appeared in a 2013 video called &quot;big butt woman farts&quot; by el_pinche_wero_korndog. This sound is often used in memes and videos to make people laugh because it&apos;s just so silly and unexpected. It&apos;s one of those sounds that can make everyone giggle, no matter how old they are!
                </p>
            </details>

            <details>
                <summary><h3>I Fart in Your General Direction</h3></summary>
                <p>
                    The phrase &quot;I fart in your general direction&quot; comes from a very funny movie called &quot;Monty Python and the Holy Grail.&quot; In this movie, a silly French guard uses this line to tease and mock the heroes in a ridiculous way. It became one of the movie&apos;s most famous quotes because it&apos;s so unexpected and hilarious. This line shows how playful and silly humor can be, making even something as simple as a fart joke part of a classic comedy moment!
                </p>
            </details>

            <details>
                <summary><h3>I Farted and a Poopy Almost Slipped Out</h3></summary>
                <p>
                    The phrase &quot;I farted and a poopy almost slipped out&quot; has become a popular meme and funny sound effect on the internet. It originally gained attention through online platforms where people share humorous sound clips. This sound is often used in memes and videos to make people laugh because it&apos;s a silly and unexpected way to talk about an embarrassing moment. Just hearing it can make anyone giggle, reminding us all that sometimes our bodies can surprise us in the funniest ways!
                </p>
            </details>

            <details>
                <summary><h3>The &quot;Aaugh&quot; Sound Effect</h3></summary>
                <p>
                    The funny &quot;Aaugh&quot; sound effect comes from a video of a man snoring loudly. This sound was first shared on the internet with the quote &quot;he swallowed a z2080 engine&quot;, and people found it so hilarious that it quickly became a popular meme. The man in the video lets out a big snore that sounds like &quot;Aaugh&quot;, making everyone laugh. This sound is often used in memes and funny videos.
                </p>
            </details>

            <details>
                <summary><h3>Goofy&apos;s Laugh &quot;Ghew Ghew Ghew&quot;</h3></summary>
                <p>
                    Goofy&apos;s famous laugh, which sounds like &quot;Ghew Ghew Ghew,&quot; is one of the most recognizable cartoon laughs in the world. This iconic laugh is performed by the voice actor who plays Goofy in the Mickey Mouse cartoons and movies. Goofy, a lovable and clumsy character, has been making people laugh since he first appeared in the 1930s. His unique laugh adds to his goofy personality and has become a signature part of his character. Kids and adults alike enjoy hearing Goofy&apos;s laugh because it&apos;s so cheerful and funny, reminding us of his playful and silly nature.
                </p>
            </details>

            <details>
                <summary><h3>Directed by Robert B. Weide</h3></summary>
                <p>
                    The &quot;Directed by Robert B. Weide&quot; meme sound comes from the ending credits of the TV show &quot;Curb Your Enthusiasm.&quot; Robert B. Weide is a director who worked on the show, and his name appears in the end credits along with a catchy tune. This meme is often used in funny videos on the internet to mark an unexpected or humorous ending, making it look like a scene from a TV show. The meme became popular because the combination of the music and the text &quot;Directed by Robert B. Weide&quot; adds a funny twist to all kinds of surprising or awkward situations.
                </p>
            </details>

            <details>
                <summary><h3>The &quot;Pedro Pedro Pedro&quot; Song</h3></summary>
                <p>
                    The &quot;Pedro Pedro Pedro&quot; song comes from a remix of a famous song by an Italian singer named Raffaella Carrà. The remix was created by German producers Jaxomy and Agatino Romero in 2024. The original song tells a story about a woman wandering the streets and meeting a boy named Pedro who offers to be her guide.
                    The remix became super popular on TikTok, especially with a funny video of a baby raccoon dancing in a circle to the beat. This video went viral in March 2024, and people loved syncing the raccoon&apos;s dance to the music. The raccoon dancing video was first posted on TikTok and quickly spread to other platforms like Instagram and YouTube, making everyone laugh with its catchy beat and adorable visuals.
                    This song and video combo show how creative people can make simple things, like a dancing raccoon, into internet sensations that bring joy to millions around the world!
                </p>
            </details>

            <details>
                <summary><h3>Keep the Change, You Filthy Animal</h3></summary>
                <p>
                    The phrase &quot;Keep the change, you filthy animal&quot; comes from a famous scene in the holiday movie &quot;Home Alone,&quot; released in 1990. In the movie, the main character, Kevin, watches an old black-and-white gangster movie called &quot;Angels with Filthy Souls.&quot; In one scene, a gangster named Johnny says this line after pretending to shoot another character.
                    Although &quot;Angels with Filthy Souls&quot; is not a real movie (it was made just for &quot;Home Alone&quot;), the scene became iconic and is often quoted, especially during the Christmas season. The phrase &quot;Keep the change, you filthy animal&quot; is used humorously in many memes and jokes online, adding a touch of tough-guy attitude to various funny situations.
                    The popularity of this line has turned it into a timeless catchphrase, reminding everyone of Kevin&apos;s clever tricks and the fun spirit of &quot;Home Alone&quot; every holiday season!
                </p>
            </details>

            <details>
                <summary><h3>The Rickroll Meme</h3></summary>
                <p>
                    The &quot;Rickroll&quot; meme is one of the internet&apos;s most famous jokes! It started with the music video for &quot;Never Gonna Give You Up,&quot; a song by Rick Astley released in 1987. In the video, Rick sings and dances to the catchy tune, which became a big hit in the late &apos;80s.
                    The Rickroll prank began in 2007 when someone posted a link online, promising something exciting, but it actually took people to Rick Astley&apos;s music video instead. This surprise trick became known as getting &quot;Rickrolled.&quot; People started using it to playfully fool friends and strangers by disguising the link to Rick&apos;s video as something else.
                    Rick Astley himself found the joke funny and has embraced his role in internet culture. The Rickroll has been used in countless pranks and has even appeared in major events and TV shows.
                </p>
            </details>

            <button className="privacy_back_last secondary_button" type="button" onClick={onPrivacyBackClick}>Back</button>
        </div>
    );
}

export default SoundTrivia;
